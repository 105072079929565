<template>
  <div class="client-page g-role-add-box-padding" v-loading="loading">
    <div class="m-form-inline-mn" data-m="client">
      <div class="search-box">
        <div>
          <input
            type="text"
            :placeholder="$t('global.keywordMsg')"
            v-model.trim="formInline.title"
            @keydown.enter="toPage(1)"
            @blur="aaaa"
            @focus="showClear=true"
          />
          <div v-if="showClear" class="clear-btn" style=" display:  flex;align-items: center; background: #ffffff; margin-left: -2px; padding:0 5px;">
            <i class="el-icon-circle-close" style="font-size: 26px; color: #999999; cursor: pointer" @click="formInline.title='',startSearch()"></i>
          </div>
          <div class="btn" @click="startSearch">
            {{ $t("global.searchText") }}
          </div>
        </div>
      </div>
      <div class="content-box">
        <div class="table-box">
          <el-empty
            v-if="tableData.length < 1"
            :description="$t('global.emptyResMsg')"
          ></el-empty>
          <div class="row" v-for="(item, index) in tableData" :key="index">
            <div
              class="video-card"
              v-for="node in item"
              :key="node.id"
              @click="toView(node.id)"
            >
              <div class="cover">
                <img
                  @error="
                    (e) => {
                      e.target.src = require('./../../../assets/img/default-img.png');
                    }
                  "
                  :src="node.coverImgUrl | preUrl"
                  alt=""
                />
                <div class="time">{{ node.videoDuration || "00:00" }}</div>
              </div>
              <div class="content">
                <div class="title norow">{{ node.title || "暂无标题" }}</div>
                <div class="tag">
                  <span v-if="node.tag"><el-tag size="small" v-for="(tag,index) in node.tagList" :key="index" style="margin-right: 10px;">{{tag}}</el-tag></span>
                  <span v-else>暂无标签</span>
                </div>
                <div class="createTime">
                  {{ $moment(node.createTime).format("YYYY-MM-DD") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination-box" v-if="tableData.length > 0">
          <div>
            <el-pagination
              background
              layout="prev, pager, next, sizes, jumper"
              :total="formInline.total"
              :page-size.sync="formInline.size"
              :current-page="formInline.page"
              @current-change="toPage"
              :page-sizes="[8, 20, 30, 40]"
              @size-change="toPage(1)"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      formInline: {
        page: 1,
        size: 8,
        totel: 0,
        title: ''
      },
      type: 1,
      loading: true,
      tableData: [],
      showClear: false
    }
  },
  created () {
    this.toPage(1)
  },
  methods: {
    startSearch () {
      // if (!this.formInline.title) return this.$message.warning(this.$t('global.seartSearchMsg'))
      this.toPage(1)
    },
    aaaa () {
      setTimeout(_ => {
        this.showClear = false
      }, 300)
    },
    toView (id) {
      this.$router.push({
        path: '/onlineLearning-view',
        query: {
          id: id
        }
      })
    },
    toPage (page) {
      this.formInline.page = page
      this.onSubmit()
    },
    spliceList (list = [], num = 4) {
      const resList = []
      let count = 0
      for (let i = 0; i < (list.length / num); i++) {
        resList[i] = list.slice(count, count + num)
        count += num
      }
      return resList
    },
    onSubmit () {
      this.loading = true
      api.tzh.queryList({
        ...this.formInline,
        queryParam: {
          type: this.type,
          title: this.formInline.title
        }
      }).then(res => {
        this.formInline.total = res.data.total
        this.loading = false

        res.data.records.forEach(ele => {
          if (ele.tag&&ele.tag.indexOf(',') >= 0) {
            ele.tagList = ele.tag.split(',')
          } else {
            ele.tagList = [ele.tag]
          }
        })
        this.tableData = this.spliceList(res.data.records || [])
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.m-form-inline-mn[data-m="client"] {
  margin-top: 0;
  padding-top: 0;
}
/deep/.pagination-box {
  position: relative;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    display: flex;
    justify-content: flex-end;
    width: 1200px;
  }
}
.client-page {
  background-color: #fff;
  height: calc(100vh - 64px);
  overflow-y: auto;
}
.search-box {
  display: flex;
  justify-content: center;
  padding: 32px 0;
  & > div {
    display: flex;
    justify-content: space-between;
    width: 542px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    input {
      outline: none;
      border: none;
      flex: 1;
      text-indent: 12px;
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .btn {
      text-align: center;
      width: 98px;
      line-height: 48px;
      background: #09a666;
      border-radius: 3px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .table-box {
    width: 1200px;
    min-height: 630px;
    .row {
      display: flex;
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 40px;
      }
      .video-card {
        &:not(:last-child) {
          margin-right: 24px;
        }
        width: 282px;
        cursor: pointer;
        height: 282px;
        background: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        transition: all 0.3s;
        &:hover {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
          transform: scale(1.05);
        }
        .cover {
          position: relative;
          width: 282px;
          height: 165px;
          background: #f6f6f6;
          border-radius: 4px 4px 0px 0px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .time {
            position: absolute;
            right: 0;
            bottom: 0;
            text-align: center;
            width: 72px;
            line-height: 22px;
            background: rgba(0, 0, 0, 0.7);
            color: #ffffff;
            border-radius: 3px 0px 0px 0px;
          }
        }
        .content {
          padding: 16px;
          .title {
            font-size: 20px;
            margin-bottom: 8px;
            line-height: 22px;
          }
          .tag {
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.65);
          }
          .createTime {
            margin-top: 12px;
            display: flex;
            align-items: center;
            line-height: 20px;
            &::before {
              content: "";
              margin-right: 8px;
              display: inline-block;
              width: 14px;
              height: 14px;
              background: url("~assets/img/icon/clock-icon.png") no-repeat
                center/cover;
            }
          }
        }
      }
    }
  }
}
</style>
